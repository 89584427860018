@import "https://esm.sh/@shoelace-style/shoelace/dist/themes/light.css";
@import "vendor/pico.css";
@import "variables.css";

/* Define a transition duration during page visits */
html.is-changing .transition-fade {
  transition: all 0.2s ease-in-out;
  opacity: 1;
  translate: 0px 0px;
}
/* Define the styles for the unloaded pages */
html.is-animating .transition-fade {
  opacity: 0;
  translate: -10px 0px;
}

main:focus {
  outline: none;
}

header#hero {
  position: relative;
  background: url("/images/homepage/live-streaming-backdrop.jpg");
  background-size: cover;
  background-position: center 80%;
  min-height: 800px;
  display: flex;
  align-items: end;
  justify-content: center;

  @media (max-width: 700px) {
    min-height: calc(100vw + 100px);
  }

  @media (min-width: 1600px) {
    min-height: 50vw;
  }

  &.no-op {
    min-height: 0px;
    padding: 0;
  }

  & video {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 60%;
    opacity: 0;
    transition: opacity .2s;
  }

  & video-gradient {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #00000000 55%, #00000099 90%);
    opacity: 0;
    transition: opacity 4s 2s;
  }
  
  & hgroup {
    text-align: center;
    margin-inline: .5rem;
    margin-block-end: 8vw;
    z-index: 1;

    & h1 {
      color: white;
      font-size: 3.75rem;
      margin-block-end: 1rem;
      text-shadow: 0px 3px 15px rgba(20,5,5,0.5);

      @media (max-width: 1023px) {
        font-size: 3.25rem;
      }
    }

    & p {
      color: var(--dax-color-violet);
      color: color-mix(in oklch, var(--dax-color-turquoise), var(--dax-color-violet) 40%);
      font-size: 2.25rem;
      text-shadow: 0px 2px 7px rgba(20,5,45,0.5);
      height: 2lh;
    }
  }
}

sl-icon {
  vertical-align: -5%;
  margin-inline-end: .35em;
}

sl-icon:first-child {
  margin-inline-end: .35em;
}

sl-icon:last-child {
  margin-inline-start: .35em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--dax-heading-font);
  font-weight: 500;
  letter-spacing: 0.02em;
}

h1 {
  --pico-font-size: var(--dax-heading-font-size);
  --pico-typography-spacing-vertical: var(--size-5);
}

h2, h3, h4 {
  color: var(--dax-color-purple);
}

body > header {
  background: var(--dax-color-navy); /* fallback */
}

body > header {
  --pico-primary: var(--dax-color-turquoise);
  --pico-primary-hover: color-mix(in oklab, var(--dax-color-turquoise), white 30%);

  background: color-mix(in oklch, var(--dax-color-navy), black 30%);
  padding-block-end: 0;

  & header-bg {
    display:block;
    background: linear-gradient(180deg, color-mix(in oklch, var(--dax-color-navy), black 30%), color-mix(in oklch, var(--dax-color-navy), black 10%), var(--dax-color-navy));
    padding-block-end: var(--pico-block-spacing-vertical);
  }

  margin-block-end: var(--size-5);

  & a:not([role=button]) > sl-icon {
    color: var(--pico-primary-hover-underline);
    transition: color var(--pico-transition);

    a:hover & {
      color: white;
    }
  }

  & img {
    transition: filter var(--pico-transition);
  }

  & inner-container > a:hover > img {
    filter: brightness(125%);
  }

  @media (max-width: 1023px) {
    padding-block-start: var(--size-2);

    nav {
      --pico-nav-element-spacing-vertical: .4rem;

      flex-direction: column;
      align-items: center;
      gap: var(--size-1);

      ul {
        margin: 0;
      }

      &[flex-collapse] {
        margin-block-start: var(--size-2);
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: var(--pico-nav-element-spacing-vertical) var(--size-3);

        ul {
          display: contents;

          li {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}

body.homepage > header {
  margin-block: 0;

  &:last-of-type {
    margin-block-end: var(--pico-block-spacing-vertical);
  }
}

body > footer {
  --pico-nav-element-spacing-vertical: .5rem;

  margin-block-start: var(--size-10);
  padding-block-start: var(--size-6);
  background: var(--gray-2);

  > .container-fluid {
    max-width: 1961px;
  }
}

a.unstyled {
  --pico-text-decoration: none;
}

p > iframe {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  border-radius: var(--dax-border-radius);
}

page-columns {
  display: grid;
  gap: var(--size-fluid-5);
  grid-template-columns: 2fr 1fr;

  & img {
    border-radius: var(--dax-border-radius);
  }

  @media (max-width: 767px) {
    grid-template-columns: minmax(100px, 1fr);
  }
}

page-column[is-sidebar] {
  & img {
    width: 100%;
  }

  & blockquote {
    margin-block-start: 0;
    border-inline-start-width: .4rem;

    & p {
      font-size: 110%;
      font-style: italic;
      color: var(--dax-color-orchid);
    }
  }
}

body.fullwidth_page {
  & page-columns {
    grid-template-columns: 1fr;
  }
}

@keyframes open-details {
  0%    {opacity: 0.2; translate: 0px -0.2rem }
  100%  {opacity: 1; translate: 0px 0px }
}

details > summary {
  margin-block-start: var(--size-7);
  background: var(--gray-1);
  border-radius: var(--dax-border-radius-large);
  padding: var(--size-3);

  > :is(h2, h3) {
    margin: 0;
    display: inline-block;
    width: calc(100% - 2rem);
  }

  &:has(:is(h2, h3)) {
    &::after {
      margin-block-start: .5em;
    }

    + * {
      margin-block-start: var(--size-7);
    }
  }
}

details[open] summary ~ * {
  animation: open-details .3s ease-in-out;
  margin-inline: var(--size-3)
}

ul > li::marker {
  color: var(--dax-color-orchid);
}

section[two-up] {
  --pico-border-radius: var(--dax-border-radius-large);

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: clamp(1rem, 2vw, 2rem);
  margin-bottom: 8rem;

  @media (max-width: 1023px) {
    &[mobile-collapse] {
      grid-template-columns: 1fr;

      [style*="order"] {
        order: 0 !important;
      }
    }
  }

  img {
    border-radius: var(--pico-border-radius);
  }

  

  > p {
    margin: 0;
    align-items: center;
    display: flex;

    /* TODO: figure out out to DRY this */
    > a {
      rotate: 0deg;
      transition: rotate 180ms ease-in-out;
      padding: 0;
      &:hover {
        rotate: -2deg;
      }
    }
  }

  > article {
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;

    &:has(> a) {
      rotate: 0deg;
      transition: rotate 180ms ease-in-out;
      padding: 0;
      &:hover {
        rotate: -2deg;
      }
    }

    > p {
      --pico-border-radius: var(--dax-border-radius);

      font-size: clamp(1em, 2.5vw, 1.15em);

      &:has([role="button"]) {
        margin-block-start: 1.5rem;
      }
    }
  }

  > article > a {
    display: grid;
    grid-template-areas: "container";
    text-decoration: none;
    
    > img {
      object-fit: cover;
      width: 100%;
      display: block;
      aspect-ratio: 3 / 2;
      grid-area: container;

      @media (max-width: 767px) {
        aspect-ratio: 5 / 4;
      }
    }
    > h2 {
      align-self: end;
      justify-self: center;
      grid-area: container;
      margin: 0;
      padding: 1rem;
      box-sizing: border-box;
      width: 100%;
      color: white;
      background: #00000088;
      background: linear-gradient(to top, #000000cc, #00000044);
      backdrop-filter: blur(5px);
      box-shadow: 0px -2px 15px #00000055;

      @media (max-width: 600px) {
        & {
          padding: 3vw;
          font-size: 5vw;
        }
      }
    }
  }
}

figure[variant="card"] {
  --pico-card-background-color: color-mix(in srgb, aliceblue, 20% white);

  margin-block-end: calc(var(--pico-block-spacing-vertical) * 2);
  padding: var(--pico-block-spacing-vertical) var(--pico-block-spacing-horizontal);
  border-radius: var(--pico-border-radius);
  background: var(--pico-card-background-color);
  box-shadow: var(--pico-card-box-shadow);
  border: 1px solid #97d7fb;

  > h2 {
    font-size: var(--font-size-3);
  }

  > blockquote {
    margin: 0;
    padding: 0;
    border: none;
  }
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(var(--minimum-grid-cell), 1fr));
  }
}
