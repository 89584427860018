@import "open-props/open-props.min.css";
@import url(https://fonts.bunny.net/css?family=pt-sans:400,400i,700,700i);
@import url(https://fonts.bunny.net/css?family=titillium-web:400,400i,700,700i);
@import url(https://fonts.bunny.net/css?family=saira-semi-condensed:500);

:root {
  --dax-color-navy: #141340;
  --dax-color-steel-blue: #205f77;
  --dax-color-cyan: #3ea1c8;
  --dax-color-turquoise: #97d7fb;
  --dax-color-purple: #4e3e6e;
  --dax-color-orchid: #875793;
  --dax-color-violet: #7e68aa;

  --dax-heading-font: "Saira Semi Condensed";
  --dax-heading-font-size: clamp(2rem, 5vw, 3rem);

  --dax-border-radius: .4em;
  --dax-border-radius-large: 1em;

  --responsive-flex-direction: row;
  --responsive-flex-gap: var(--size-8);

  --minimum-grid-cell: 250px;
}

@media (max-width: 1023px) {
  :root {
    --responsive-flex-direction: column;
    --responsive-flex-gap: 0;
  }
}

@media (max-width: 575px) {
  :root {
    --mobile-grid-template: 1fr;
    --mobile-left: left;
  }
}

/* Pico overrides */
[data-theme=light],
:root:not([data-theme=dark]) {
  --pico-font-family: "Titillium Web", sans-serif;
  --pico-primary: var(--dax-color-steel-blue);
  --pico-primary-background: var(--dax-color-cyan);
  --pico-primary-hover: color-mix(in hsl, var(--dax-color-steel-blue), var(--dax-color-turquoise) 40%);
  --pico-primary-hover-background: var(--dax-color-steel-blue);
  --pico-muted-color: var(--dax-color-orchid);
  --pico-border-radius: var(--dax-border-radius);
}

inner-container {
  display: block;
}
